import { P, match } from 'ts-pattern';
import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type {
  Address,
  AdminDonation,
  CreateDonationInput,
  CreateGrantInput,
  Donation,
  DonationPledgeDetails,
  DonationRecipient,
  EntityLabel,
  StockTicker,
  SupportedBroker,
  UUID,
  UserIdentityInfo,
} from '@endaoment-frontend/types';
import {
  adminDonationSchema,
  arraySchemaInvalidsFiltered,
  donationPledgeDetailsSchema,
  donationSchema,
  isUuid,
  stockTickerSchema,
  supportedBrokerSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

import { GetOrg } from './orgs';

export const convertDonationRecipientToEntityLabel = (
  donationRecipient: DonationRecipient,
): Promise<Omit<EntityLabel, 'name'>> => {
  return Promise.resolve(
    match(donationRecipient)
      .with({ type: 'fund' }, recipient => ({ type: 'fund', id: recipient.id }) as const)
      .with(
        { type: 'org', subprojectId: P.not(P.nullish) },
        recipient => ({ type: 'subproject', id: recipient.subprojectId }) as const,
      )
      .with({ type: 'org' }, async recipient => {
        const orgId = isUuid(recipient.einOrId)
          ? recipient.einOrId
          : (await GetOrg.fetchFromDefaultClient([recipient.einOrId])).id;
        return { type: 'org', id: orgId } as const;
      })
      .exhaustive(),
  );
};

export const GetTotalDonations = new RequestHandler(
  'GetTotalDonations',
  fetch => async () => {
    try {
      return z.string().parse(await fetch('/v1/donations/total'));
    } catch {
      return '0';
    }
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/donations/total` }),
  },
);

export const GetDonationsCount = new RequestHandler(
  'GetDonationsCount',
  fetch => async () => {
    try {
      return z.number({ coerce: true }).parse(await fetch('/v1/donations/count'));
    } catch {
      return 0;
    }
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/donations/count` }),
  },
);

export const GetDestinationDonations = new RequestHandler(
  'GetDestinationDonations',
  fetch =>
    async (id: UUID): Promise<Array<Donation>> => {
      const res = await fetch(`/v1/donations/destination/${id}`);
      return z.array(donationSchema).parse(res);
    },
);

type RegisterDonationBody = {
  donationTransactionHash: Address;
  donorName?: string;
  donorIdentity?: UserIdentityInfo;
  subprojectId?: UUID;
  shareMyEmail: boolean;
  chainId: number;
  recommendationIds?: Array<UUID>;
};
export const RegisterDonation = new RequestHandler(
  'RegisterDonation',
  fetch =>
    async (donationTransactionHash: Address, chainId: number, args: CreateDonationInput): Promise<Donation> => {
      const { donorIdentity, subprojectId, taxReceipt, shareMyEmail, recommendationId } = args;
      const res = await fetch('/v1/donations', {
        method: 'POST',
        body: {
          donationTransactionHash,
          donorIdentity: taxReceipt ? donorIdentity : undefined,
          subprojectId,
          shareMyEmail: taxReceipt ? shareMyEmail : false,
          chainId,
          recommendationIds: recommendationId ? [recommendationId] : undefined,
        } satisfies RegisterDonationBody,
      });
      return donationSchema.parse(res);
    },
  { isUserSpecificRequest: true },
);

export const GetAdminDonations = new RequestHandler(
  'GetAdminDonations',
  fetch =>
    async (count?: number, offset?: number, entityName?: string): Promise<Array<AdminDonation>> => {
      const res = await fetch('/v1/donations/all', {
        params: {
          count,
          offset,
          entityName,
        },
      });
      return arraySchemaInvalidsFiltered(adminDonationSchema).parse(res);
    },
);

export const GetStockTickers = new RequestHandler(
  'GetStockTickers',
  fetch =>
    async (search?: string): Promise<Array<StockTicker>> => {
      const res = await fetch('/v1/stocks/tickers', {
        query: {
          ticker: search,
        },
      });

      return z.object({ tickers: z.array(stockTickerSchema) }).parse(res).tickers;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/stocks/tickers`,
    }),
  },
);

export const GetStockTickerPrice = new RequestHandler(
  'GetStockTickerPrice',
  fetch =>
    async (ticker?: string): Promise<number> => {
      if (!ticker) throw new Error('Ticker is required');

      const res = await fetch('/v1/stocks/price', {
        query: {
          ticker,
        },
      });
      return z.object({ price: z.number() }).parse(res).price;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/stocks/price`,
    }),
  },
);

export const GetSupportedBrokers = new RequestHandler(
  'GetSupportedBrokers',
  fetch =>
    async (search?: string): Promise<Array<SupportedBroker>> => {
      const res = await fetch('/v1/stocks/tgb-brokers');
      const brokers = z.object({ brokers: z.array(supportedBrokerSchema) }).parse(res).brokers;

      if (!search) return brokers;

      return brokers.filter(
        broker =>
          broker.name.toLowerCase().includes(search.toLowerCase()) ||
          broker.label.toLowerCase().includes(search.toLowerCase()),
      );
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/stocks/tgb-brokers`,
    }),
  },
);

type DafMigrationPledgeInput = {
  amount: bigint;
  receivingFundId: UUID;
  idempotencyKey: string;
};
type DafMigrationPledgeBody = {
  pledgedAmountMicroDollars: string;
  receivingFundId: string;
  idempotencyKey: string;
};
export const CreateDafMigrationPledge = new RequestHandler(
  'CreateDafMigrationPledge',
  fetch =>
    async ({ amount, receivingFundId, idempotencyKey }: DafMigrationPledgeInput) => {
      const res = await fetch('v1/donation-pledges/daf-migration', {
        method: 'POST',
        body: {
          idempotencyKey,
          pledgedAmountMicroDollars: amount.toString(),
          receivingFundId,
        } satisfies DafMigrationPledgeBody,
      });
      return z
        .object({
          id: uuidSchema,
        })
        .parse(res).id;
    },
);

type StripeDonationBody = {
  paymentMethod: 'Card' | 'Custom' | 'UsBankTransfer';
  pledgedAmountCents: number;
  donorIdentity?: UserIdentityInfo;
  updateIdentity?: boolean;
  shareMyEmail?: boolean;
  receivingEntityType: 'fund' | 'org' | 'subproject';
  receivingEntityId: UUID;
  // TODO: See if we can remove isRebalanceRequested
  isRebalanceRequested: boolean;
  recommendationId?: UUID;
};
const stripeDonationResponseSchema = z.object({
  id: uuidSchema,
  clientSecret: z.string(),
});
export const StartCashDonation = new RequestHandler(
  'StartCashDonation',
  fetch =>
    async ({
      pledgedAmountCents,
      recipient,
      donorIdentity,
      shareMyEmail,
      updateIdentity,
      recommendationId,
    }: Pick<
      StripeDonationBody,
      'donorIdentity' | 'pledgedAmountCents' | 'recommendationId' | 'shareMyEmail' | 'updateIdentity'
    > & {
      recipient: DonationRecipient;
    }): Promise<z.infer<typeof stripeDonationResponseSchema>> => {
      const { id: receivingEntityId, type: receivingEntityType } =
        await convertDonationRecipientToEntityLabel(recipient);

      const res = await fetch('v1/donation-pledges/stripe', {
        method: 'POST',
        body: {
          paymentMethod: 'Custom',
          pledgedAmountCents,
          donorIdentity,
          shareMyEmail,
          updateIdentity,
          receivingEntityType,
          receivingEntityId,
          isRebalanceRequested: false,
          recommendationId,
        } satisfies StripeDonationBody,
      });
      return stripeDonationResponseSchema.parse(res);
    },
);

export const GetDonationPledge = new RequestHandler(
  'GetDonationPledge',
  fetch =>
    async (id: UUID): Promise<DonationPledgeDetails> => {
      const res = await fetch(`/v1/donation-pledges/${id}`);
      return donationPledgeDetailsSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/donation-pledges/:id` }),
  },
);

type CreateGrantRecommendationInput = CreateGrantInput & {
  amountUsdc: bigint;
  orgId: UUID;
  offsetFee: boolean;
  collaboratingFundId: UUID;
  uuid: UUID;
};
type CreateGrantRecommendationBody = Omit<CreateGrantRecommendationInput, 'amountUsdc'> & {
  amountUsdc: string;
};

export const CreateGrantRecommendation = new RequestHandler(
  'CreateGrantRecommendation',
  fetch =>
    async (body: CreateGrantRecommendationInput): Promise<void> => {
      await fetch(`/v1/recommendations/grant`, {
        method: 'POST',
        body: {
          ...body,
          amountUsdc: body.amountUsdc.toString(),
        } satisfies CreateGrantRecommendationBody,
      });
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/recommendations/grant` }),
  },
);

type CreateErcRecommendationInput = Omit<CreateDonationInput, 'recommendationId'> & {
  collaboratingFundId: UUID;
  tokenId: number;
  chainId: number;
  inputAmount: bigint;
  offsetFee: boolean;
  uuid: UUID;
};
type CreateErcRecommendationBody = Omit<CreateErcRecommendationInput, 'inputAmount'> & {
  inputAmount: string;
};
export const CreateErcRecommendation = new RequestHandler(
  'CreateErcRecommendation',
  fetch =>
    async ({ inputAmount, ...input }: CreateErcRecommendationInput): Promise<void> => {
      await fetch(`/v1/recommendations/donation`, {
        method: 'POST',
        body: {
          ...input,
          inputAmount: inputAmount.toString(),
        } satisfies CreateErcRecommendationBody,
      });
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/recommendations/donation` }),
  },
);
